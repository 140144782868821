import { Optional } from '@angular/core';
import { AbstractModel } from '@core/base-model';
import { Paginated } from '@targx/types/pagination/paged.type';

export class Inbox extends AbstractModel {
  [key: string]: any;

  constructor(
    id?: number
  ) {
    super();
  }

  bootstrap() {

  }
}

export class PaginatedInbox extends Paginated(Inbox) {
  constructor(@Optional() data: PaginatedInbox) {
    data.items = data.items.map(item => {
      item = Object.assign(new Inbox(), item);
      item.bootstrap();
      return item;
    });
    super(data.items, data.meta);
  }
}
