import { inject, Injectable } from '@angular/core';
import { AbstractService } from '@core/abstract.service';
import { ApiService } from '@targx/index';
import { catchError, map, Observable, of, take, tap } from 'rxjs';
import { FindAllInboxDto } from './dto/find-all-inbox.dto';
import { Inbox, PaginatedInbox } from './inbox';

@Injectable({
  providedIn: 'root'
})
export class InboxService extends AbstractService<Inbox, PaginatedInbox> {

  private _api = inject(ApiService);

  public findAll$(args?: FindAllInboxDto): Observable<PaginatedInbox> {
    return this._api
      .post<PaginatedInbox>('/v1/inbox/find-all', {
        ...args,
      })
      .pipe(
        map((response: PaginatedInbox) => {
          return new PaginatedInbox(response);
        }),
        tap(response => {
          this.items = response.items;
          this.pagination = response.meta;
        })
      );
  }

  public findAll(args?: FindAllInboxDto): Observable<PaginatedInbox> {
    return this._api
      .post<PaginatedInbox>('/v1/inbox/find-all', {
        ...args,
      })
      .pipe(
        map((response: PaginatedInbox) => {
          return new PaginatedInbox(response);
        })
      );
  }

  public findOne(id: number): Observable<Inbox> {
    if (!id) {
      return of(new Inbox());
    }

    return this._api
      .post<PaginatedInbox>('/v1/inbox/find-all', {
        where: {
          id: {
            equals: id
          }
        }
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        tap(response => {
          this.item = response.items[0];
        }),
        catchError(error => {
          throw error;
        })
      );
  }

  /**
 * Reset the current mail
 */
  resetMail(): Observable<boolean> {
    return of(true).pipe(
      take(1),
      tap(() => {
        this.item = null;
      })
    );
  }

  public markAsRead(id: number): Observable<boolean> {
    return this._api
      .patch(`/v1/inbox/${id}/mark-as-read`)
      .pipe(
        take(1),
        map(() => {
          this.refresh = true;
          return true;
        })
      );
  }

  getTotalUnreadMails(): Observable<number> {
    return this._api
      .get('/v1/inbox/total-unread')
      .pipe(
        take(1),
        map((response: any) => {
          return response;
        })
      );
  }

}
